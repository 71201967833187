


















import { Component, Vue } from 'vue-property-decorator'
import {
    updateAuthorization,
    me,
} from '@/api/userapi/users'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsParentAuthorization',
})
export default class extends Vue {
    private parentAuthorization = false
    private loadingParentAuth = false

    get user () {
        return UserModule.user
    }

    set user (user: any) {
        UserModule.SET_USER(user)
    }

    get showCheckboxAuth () {
        return this.user?.parent.id
    }

    beforeMount () {
        this.parentAuthorization = this.user?.parent?.authorized || false
    }

    private async updateParentAuthorization () {
        if (this.loadingParentAuth) return
        this.loadingParentAuth = true
        try {
            await updateAuthorization(this.parentAuthorization)
            this.$root.$vsToast({
                heading: this.$t('account.settings.successMessages.authorizationSaved'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getUser()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('account.settings.errorMessages.authorizationUpdateAssociatedManagerError'),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loadingParentAuth = false
    }

    private async getUser () {
        try {
            const resp = await me()
            this.user = resp.data
            this.parentAuthorization = this.user.parent.authorized
        } catch (e) {
            console.log(e)
        }
    }
}
