







import { Component, Vue } from 'vue-property-decorator'
import VsParentAuthorization from '@/modules/account/components/VsParentAuthorizationCard/Index.vue'
import VsTFACardV2 from '@/modules/account/components/VsTFACardV2/Index.vue'
import VsChangePasswordCardV2 from '@/modules/account/components/VsChangePasswordCardV2/Index.vue'

@Component({
    name: 'Security',
    components: {
        VsParentAuthorization,
        VsTFACardV2,
        VsChangePasswordCardV2,
    },
})
export default class extends Vue {
}
