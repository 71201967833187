















import { Component, Vue } from 'vue-property-decorator'
import VsInputPassword from '@/components/VsInputPassword/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { recoverPassword } from '@/api/userapi/users'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsChangePasswordCardV2',
    components: {
        VsInputPassword,
    },
})
export default class extends Vue {
    private loading = false
    private passwordType = 'password'
    private myPasswordData = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    }

    $refs!: {
        changePasswordForm: any
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get user () {
        return UserModule.user
    }

    private async changePassword () {
        this.loading = true
        try {
            await recoverPassword(this.user.email)
            this.$root.$vsToast({
                heading: this.$t('login.checkInbox'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Qualcosa è andato storto. Riprova più tardi.',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}
