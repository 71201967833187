
















































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import VsVerifyTwoFaModalV2, {
    ITwoFaOperation,
    twoFaOperationType,
    twoFaOperationLabel,
} from '@/modules/account/components/VsVerifyTwoFaModalV2/Index.vue'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    turnOn2FaCode,
    me,
} from '@/api/userapi/users'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsTFACardV2',
    components: {
        VsVerifyTwoFaModalV2,
    },
})
export default class extends Vue {
    private loading = false
    private code = ''
    private twoFaObject = {
        qrCodeImg: '',
        twoFaSecret: '',
    }

    $refs!: {
        vsVerifyTwoFaModalV2: VsVerifyTwoFaModalV2
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get user () {
        return UserModule.user
    }

    set user (val: any) {
        UserModule.SET_USER(val)
    }

    get twoFaEnabled () {
        if (!this.user) return false
        return this.user.twoFactorAuthEnabled
    }

    private openVerifyTwoFaModal (operation: twoFaOperationType) {
        let twoFaOperation: ITwoFaOperation
        switch (operation) {
            case twoFaOperationType.generate:
                twoFaOperation = {
                    type: twoFaOperationType.generate,
                    label: twoFaOperationLabel.generate,
                }
                break
            case twoFaOperationType.deactivate:
                twoFaOperation = {
                    type: twoFaOperationType.deactivate,
                    label: twoFaOperationLabel.deactivate,
                }
                break
            case twoFaOperationType.view:
                twoFaOperation = {
                    type: twoFaOperationType.view,
                    label: twoFaOperationLabel.view,
                }
                break
            default:
                twoFaOperation = {
                    type: twoFaOperationType.generate,
                    label: twoFaOperationLabel.generate,
                }
                break
        }

        this.$refs.vsVerifyTwoFaModalV2.openModal(twoFaOperation)
    }

    private copyToClipboard (stringToCopy: any) {
        try {
            copyToClipboard(stringToCopy)
            this.$root.$vsToast({
                heading: this.$t('account.settings.successMessages.copySaved'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('account.settings.errorMessages.somethingWentWrong'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async checkTwoFaCode () {
        if (this.loading) return
        this.loading = true
        const data = {
            code: this.code,
        }
        try {
            await turnOn2FaCode(data)
            this.$root.$vsToast({
                heading: this.$t('account.settings.successMessages.twoFactorActivated'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            await this.getUser()
            this.twoFaObject.qrCodeImg = ''
            this.loading = false
            this.code = ''
        } catch (error) {
            this.$root.$vsToast({
                heading: this.$t('account.settings.errorMessages.twoFactorWrongCode'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    private async getUser () {
        const resp = await me()
        this.user = resp.data
    }
}
