




































import { Component, Vue } from 'vue-property-decorator'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import {
    generate2FaCode,
    turnOff2FaCode,
    retrieve2FaCode,
} from '@/api/userapi/users'
import { get } from 'lodash'

export enum twoFaOperationType {
    generate = 'generate',
    deactivate = 'deactivate',
    view = 'view',
}

export enum twoFaOperationLabel {
    generate = 'Genera',
    deactivate = 'Disattiva',
    view = 'Visualizza',
}

export interface ITwoFaOperation {
    type?: twoFaOperationType
    label?: twoFaOperationLabel
}

@Component({
    name: 'VsVerifyTwoFaModalV2',
})
export default class extends Vue {
    private open = false
    private password = ''
    private loading = false
    $refs: any

    private twoFaOperation: ITwoFaOperation = {
        type: twoFaOperationType.generate,
        label: twoFaOperationLabel.generate,
    }

    public openModal (twoFaOperation: ITwoFaOperation) {
        this.loading = false
        this.password = ''
        this.twoFaOperation = twoFaOperation
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    private async submitForm () {
        const valid = await this.$refs.twoFaForm.validate()
        if (!valid) return
        if (this.twoFaOperation.type === 'generate') {
            return this.generateTwoFaCode()
        }
        if (this.twoFaOperation.type === 'deactivate') {
            return this.turnOffTwoFaCode()
        }
        if (this.twoFaOperation.type === 'view') {
            return this.viewTwoFaCode()
        }
    }

    private async generateTwoFaCode () {
        if (this.loading) return
        this.loading = true
        try {
            const data = {
                password: this.password,
            }
            const response = await generate2FaCode(data)
            this.$emit('input', {
                qrCodeImg: response.data.qrCode,
                twoFaSecret: '',
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
            const errorStatus = get(e, 'response.status', [])
            if (errorStatus === 401) {
                this.passwordError()
                this.loading = false
                return
            }
            this.genericError()
        }
        this.loading = false
    }

    private async turnOffTwoFaCode () {
        if (this.loading) return
        this.loading = true
        try {
            const data = {
                password: this.password,
            }
            await turnOff2FaCode(data)

            this.$emit('input', {
                qrCodeImg: '',
                twoFaSecret: '',
            })
            this.$emit('disabled')
            this.$root.$vsToast({
                heading: 'L\'autenticazione a due fattori è stata disattivata con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
            const errorStatus = get(e, 'response.status', [])
            if (errorStatus === 401) {
                this.passwordError()
                this.loading = false
                return
            }

            this.genericError()
        }
        this.loading = false
    }

    private async viewTwoFaCode () {
        if (this.loading) return
        this.loading = true
        try {
            const data = {
                password: this.password,
            }
            const response = await retrieve2FaCode(data)
            this.$emit('input', {
                qrCodeImg: '',
                twoFaSecret: response.data.secret,
            })

            this.closeModal()
        } catch (e) {
            console.log(e)
            const errorStatus = get(e, 'response.status', [])
            if (errorStatus === 401) {
                this.passwordError()
                this.loading = false
                return
            }

            this.genericError()
        }
        this.loading = false
    }

    private genericError () {
        this.$root.$vsToast({
            heading: 'Qualcosa è andato storto, prova più tardi',
            aspect: VsToastAspectEnum.alert,
            timeout: 3000,
        })
    }

    private passwordError () {
        this.$root.$vsToast({
            heading: 'Password errata',
            aspect: VsToastAspectEnum.alert,
            timeout: 3000,
        })
    }
}
